import axios from "axios";

export interface BatchAmount {
  batchId: number;
  materialId: number;
  quantity: number;
  warehouseId: number;
  weight: number;
}

export interface LinkedBatch {
  materialId: number;
  measurementUnitId: number;
  nextBatchId: number;
  sourceBatchId: number;
  sourceMaterialId: number | null;
  quantity: number;
  warehouseId: number;
  weightPerUnit: number;
  createdDate: string;
}

export interface BatchGraphDetails {
  batchAmounts: BatchAmount[];
  linkedBatches: LinkedBatch[];
}

export const getBatchGraphDetails = (
  batchId: string
): Promise<BatchGraphDetails> => {
  return axios
    .get("traceability/maintenance/getBatchGraphDetails", {
      params: { batchId },
    })
    .then((res) => res.data);
};
