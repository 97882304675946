import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import UserProfile from "components/UserProfile";
import QuickLaunchButtons from "components/QuickLaunchButtons";
import Search from "components/Search";

import { ROUTES } from "consts";
import LOGO_URL from "fe-shared/src/assets/images/logo.svg";

import styles from "./Header.module.scss";

const Header = () => {
  const { t } = useTranslation();

  return (
    <header className={styles.etHeader}>
      <div className={styles.etHeaderLogoContainer}>
        <Link to={ROUTES.root} className={styles.etHeaderLogo}>
          <img
            src={LOGO_URL}
            alt={t("logoAltText")}
            className={styles.etHeaderLogoImage}
          />
        </Link>
      </div>
      <Search />
      <QuickLaunchButtons />
      <UserProfile />
    </header>
  );
};

export default Header;
