import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "fe-shared/src/components";
import { LogoutIcon } from "fe-shared/src/components/Icons";
import { EMPTY_USER_DATA } from "consts";
import UserContext from "contexts/userContext";

import styles from "./NotPermissionPage.module.scss";

import * as api from "./modules/api";

const NotPermissionPage = () => {
  const { t } = useTranslation();

  const { setData } = useContext(UserContext);

  const onLogoutClick = () => {
    api.userLogout().then(() => {
      setData(EMPTY_USER_DATA);
    });
  };

  return (
    <div className={styles.etNotFound}>
      <h1>{t("notPermissionPage.title")}</h1>
      <p className={styles.etNotFoundContent}>
        {t("notPermissionPage.description")}
      </p>
      <div className={styles.logOut}>
        <Button
          aria-label="Logout"
          id="user-without-permissions-logout-button"
          onClick={onLogoutClick}
          className={styles.userProfileLogoutButton}
        >
          {t("notPermissionPage.btnTitle")}
          <LogoutIcon fillColor={null} />
        </Button>
      </div>
    </div>
  );
};

export default NotPermissionPage;
