import React, { useContext, useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";

import Header from "components/Header";
import Navigation from "components/Navigation";
import {
  EndMarketIcon,
  FacilityIcon,
  SupportingDocsIcon,
  TransactionsIcon,
  SettingsIcon,
  TransfersIcon,
  AssignmentIcon,
  InvoiceIcon,
  FolderOpenIcon,
  PaperFormIcon,
  InventoryAdjustmentsIcon,
} from "fe-shared/src/components/Icons";
import UserContext from "contexts/userContext";
import {
  ExternalApp,
  Loader,
  NotFoundPage,
  SocketManager,
} from "fe-shared/src/components";
import { PermissionLevel } from "fe-shared/src/types/domain";
import Maintenance from "routes/Maintenance";
import NotPermissionPage from "components/NotPermissionPage";
import { hasPermission } from "fe-shared/src/helpers/permissions";

import config from "config";
import { ROUTES, REFRESH_TOKEN_URL, PERMISSIONS } from "consts";

import styles from "./PortalView.module.scss";

interface Props {
  forbiddenCallback: () => void;
}

const ROUTES_MAP = [
  {
    path: ROUTES.transactions,
    name: "Transactions",
    feature: "Transactions",
    label: "transactions",
    icon: TransactionsIcon,
    host: config.TRANSACTIONS_HOST_URL,
    permission: { name: PERMISSIONS.transactions, level: PermissionLevel.Read },
  },
  {
    path: ROUTES.transfers,
    name: "Transactions",
    feature: "Transfers",
    label: "transfers",
    icon: TransfersIcon,
    host: config.TRANSACTIONS_HOST_URL,
    permission: { name: PERMISSIONS.transfers, level: PermissionLevel.Read },
  },
  {
    path: ROUTES.inventoryAdjustments,
    name: "Transactions",
    feature: "InventoryAdjustments",
    label: "inventoryAdjustments",
    icon: InventoryAdjustmentsIcon,
    host: config.TRANSACTIONS_HOST_URL,
    permission: {
      name: PERMISSIONS.inventoryAdjustments,
      level: PermissionLevel.Read,
    },
  },
  {
    path: ROUTES.invoices,
    name: "Transactions",
    feature: "Invoices",
    label: "invoices",
    icon: InvoiceIcon,
    host: config.TRANSACTIONS_HOST_URL,
    permission: { name: PERMISSIONS.invoices, level: PermissionLevel.Read },
  },
  {
    path: ROUTES.remittances,
    name: "Remittances",
    feature: "Remittances",
    label: "remittances",
    icon: FolderOpenIcon,
    host: config.REMITTANCES_HOST_URL,
    permission: { name: PERMISSIONS.remittances, level: PermissionLevel.Read },
  },
  {
    path: ROUTES.applications,
    name: "Applications",
    feature: "Applications",
    label: "applications",
    icon: SupportingDocsIcon,
    host: config.APPLICATIONS_HOST_URL,
    permission: {
      name: PERMISSIONS.applications,
      level: PermissionLevel.Read,
    },
  },
  {
    path: ROUTES.participants,
    name: "Applications",
    feature: "Participants",
    label: "participants",
    icon: FacilityIcon,
    host: config.APPLICATIONS_HOST_URL,
    permission: { name: PERMISSIONS.participants, level: PermissionLevel.Read },
  },
  {
    path: ROUTES.endMarket,
    name: "Applications",
    feature: "EndMarket",
    label: "endMarketTitle",
    icon: EndMarketIcon,
    host: config.APPLICATIONS_HOST_URL,
    permission: { name: PERMISSIONS.endMarket, level: PermissionLevel.Read },
  },
  {
    path: ROUTES.requests,
    name: "Requests",
    feature: "Requests",
    label: "requests",
    icon: AssignmentIcon,
    host: config.REQUESTS_HOST_URL,
    permission: { name: PERMISSIONS.requests, level: PermissionLevel.Read },
  },
  {
    path: ROUTES.admin,
    name: "Admin",
    feature: "Admin",
    label: "admin",
    icon: SettingsIcon,
    host: config.ADMIN_HOST_URL,
    permission: {
      name: PERMISSIONS.platformSettings,
      level: PermissionLevel.Read,
    },
  },
  {
    path: ROUTES.paperForms,
    name: "Applications",
    feature: "PaperForms",
    label: "paperForms",
    icon: PaperFormIcon,
    host: config.APPLICATIONS_HOST_URL,
    permission: {
      name: PERMISSIONS.paperForms,
      level: PermissionLevel.Read,
    },
  },
];

const PortalView = ({ forbiddenCallback }: Props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { sessionId, user, setData } = useContext(UserContext);

  const routes = ROUTES_MAP.filter(({ permission }) =>
    hasPermission(user.data?.permissions, permission)
  );
  const firstRoutePath = routes[3]?.path; // change start page to InvoiceView
  const needsRedirect = pathname === ROUTES.root && firstRoutePath;

  useEffect(() => {
    if (needsRedirect) {
      history.replace(firstRoutePath);
    }
  }, [history, needsRedirect, firstRoutePath]);

  if (needsRedirect) {
    return <Loader />;
  }
  if (!routes.length) {
    return <NotPermissionPage />;
  }
  return (
    <SocketManager socketUrl={`${config.API_HOST_URL}notificationHub`}>
      <Header />
      <main className={styles.etPortalView}>
        <Navigation routes={routes} />
        <div className={styles.etPortalViewContainer}>
          <Switch>
            {routes.map(({ path, name, host, feature }) => (
              <Route key={feature} path={path}>
                <ExternalApp
                  user={user}
                  name={name}
                  feature={feature}
                  host={host}
                  sessionId={sessionId}
                  apiBaseUrl={config.API_HOST_URL}
                  refreshTokenUrl={REFRESH_TOKEN_URL}
                  setUserData={setData}
                  externalAppUrl={config.EXTERNAL_APP_URL}
                  forbiddenCallback={forbiddenCallback}
                />
              </Route>
            ))}
            <Route path="/maintenance">
              <Maintenance />
            </Route>
            <Route path="*">
              <NotFoundPage
                returnLink={routes[0].path}
                descriptions={[`page404.${routes[0].label}.firstDescription`]}
                returnLinkDescription={`page404.${routes[0].label}.buttonName`}
              />
            </Route>
          </Switch>
        </div>
      </main>
    </SocketManager>
  );
};

export default PortalView;
