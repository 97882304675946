import { useContext, useEffect } from "react";
import SocketContext from "../contexts/socketContext";

const useSocket = (
  userToken: string,
  event: string,
  groupName: string,
  userMail: string,
  onNewNotification: () => void
) => {
  const { isConnected, connect, disconnect, listen, unlisten } = useContext(
    SocketContext
  );

  useEffect(() => {
    if (!isConnected && userToken) {
      connect(groupName, userMail);
    }
  }, [groupName, userMail, userToken, isConnected, connect]);

  useEffect(() => {
    if (isConnected) {
      listen(event, onNewNotification);
    }

    return () => {
      if (isConnected) {
        unlisten(event, onNewNotification);
      }
    };
  }, [onNewNotification, isConnected, listen, unlisten, event]);

  useEffect(() => {
    return () => {
      if (isConnected) {
        disconnect();
      }
    };
  }, [isConnected, disconnect]);
};

export default useSocket;
