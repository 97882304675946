import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ArrowRight } from "fe-shared/src/components/Icons";

import {
  Participant as ParticipantResultDataItem,
  SearchResultTransactionItem,
} from "../../../../types";

import styles from "../../Search.module.scss";
import SearchContext from "../../SearchContext";
import LinkedTransactions from "../LinkedTransactions";

import {
  getHighlightedTextMarkdown,
  getEntityUrl,
  enrichTransactionDisplayName,
} from "./helpers";

interface Props {
  item: SearchResultTransactionItem;
}

interface ParticipantProps {
  participant: ParticipantResultDataItem;
  searchTerm: string;
}

const Participant = ({ participant, searchTerm }: ParticipantProps) => {
  return (
    <>
      {participant.displayName && (
        <div className={styles.searchResultsItemColumn}>
          <ReactMarkdown>
            {getHighlightedTextMarkdown(participant.displayName, searchTerm)}
          </ReactMarkdown>
          <div className={styles.subtitleContainer}>
            {participant.regNumber && (
              <ReactMarkdown className={styles.subtitle}>
                {getHighlightedTextMarkdown(participant.regNumber, searchTerm)}
              </ReactMarkdown>
            )}
            {participant.businessRoleCode && (
              <div className={styles.subtitle}>
                {participant.businessRoleCode}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const SearchResultItemTransaction = ({ item }: Props) => {
  const searchTerm = useContext(SearchContext);
  const { t } = useTranslation();

  return (
    <Link
      className={styles.searchResultsItem}
      target="_blank"
      to={getEntityUrl(item)}
    >
      <div className={styles.searchResultsItemColumn}>
        <ReactMarkdown className={styles.itemTitle}>
          {getHighlightedTextMarkdown(
            enrichTransactionDisplayName(
              item.displayName,
              t(`search.transactionType.${item.transactionType}`)
            ),
            searchTerm
          )}
        </ReactMarkdown>
        {item.transactionType && item.linkedTransactionType && (
          <div className={styles.subtitleContainer}>
            <LinkedTransactions
              transactionType={item.transactionType}
              linkedTransactionType={item.linkedTransactionType}
              linkedTransactionNumber={item.linkedTransactionNumber}
            />
          </div>
        )}
      </div>

      {item.content?.participants.length > 0 &&
        item.content.participants
          .sort((a) => (a.type === "target" ? 1 : -1))
          .map((participant, index) => (
            <>
              {index > 0 && <ArrowRight fillColor="#27a83b" />}
              <Participant
                key={participant.regNumber}
                participant={participant}
                searchTerm={searchTerm}
              />
            </>
          ))}
    </Link>
  );
};

export default SearchResultItemTransaction;
