import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import UserContext from "contexts/userContext";
import { ArrowLeft, ArrowRight } from "fe-shared/src/components/Icons";
import { Button, NavigationLink, HelpPortal } from "fe-shared/src/components";

import { get, set } from "fe-shared/src/helpers/storage";
import useMediaQuery from "fe-shared/src/hooks/useMediaQuery";

import config from "config";

import styles from "./Navigation.module.scss";

interface NavigationRoute {
  path: string;
  label: string;
  icon: React.ElementType;
}

export interface Props {
  routes: NavigationRoute[];
}

const Navigation = ({ routes }: Props) => {
  const [isCollapsed, setCollapsed] = useState<boolean>(() =>
    get("navigationCollapsed")
  );
  const { t } = useTranslation();
  const isMediaQuery = useMediaQuery("(max-width: 1024px)");
  const userContext = useContext(UserContext);
  const token = userContext.user?.token;

  useEffect(() => {
    const collapsedState: boolean = isMediaQuery
      ? get("navigationCollapsed")
      : false;

    setCollapsed(collapsedState);
  }, [isMediaQuery, setCollapsed]);

  const changeNavigationState = () => {
    set("navigationCollapsed", !isCollapsed);
    setCollapsed(!isCollapsed);
  };

  return (
    <nav
      className={classnames({
        [styles.navigation]: true,
        [styles.navigationCollapsed]: isCollapsed,
      })}
    >
      <div
        className={classnames({
          [styles.navigationWrapper]: true,
          [styles.navigationWrapperCollapsed]: isCollapsed,
        })}
      >
        <Button
          id="collapse-menu"
          className={classnames({
            [styles.navigationCollapseButton]: true,
            [styles.navigationButtonCollapsed]: isCollapsed,
          })}
          variant="outlined"
          iconComponent={isCollapsed ? ArrowRight : ArrowLeft}
          iconPosition="left"
          onClick={changeNavigationState}
        >
          {!isCollapsed && "Collapse"}
        </Button>
        <ul>
          {routes.map(({ icon: Icon, label, path }) => {
            return (
              <li key={label}>
                <NavigationLink
                  className={classnames({
                    [styles.navigationLink]: true,
                    [styles.navigationLinkCollapsed]: isCollapsed,
                  })}
                  activeClassName={styles.navigationLinkActive}
                  iconClassName={classnames({
                    [styles.navigationLinkIconCollapsed]: isCollapsed,
                  })}
                  link={path}
                  text={t(label)}
                  iconComponent={Icon}
                  isCollapsed={isCollapsed}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        <HelpPortal
          isCollapsed={isCollapsed}
          link={`${config.HELP_PORTAL_URL}?token=${token}`}
        />
      </div>
    </nav>
  );
};

export default Navigation;
