import axios, { AxiosPromise, AxiosResponse } from "axios";
import { EntityType, SearchResultGroup } from "../../../types";
import { RESULTS_SIZE } from "../config";

interface SearchParams {
  searchTerm: string;
  searchIn: EntityType[];
  page?: number;
}

const shouldSearchFinance = (searchIn: EntityType[]): boolean =>
  searchIn.includes(EntityType.Invoice) ||
  searchIn.includes(EntityType.Remittance);

const shouldSearchParticipants = (searchIn: EntityType[]): boolean =>
  searchIn.includes(EntityType.Participant) ||
  searchIn.includes(EntityType.EndMarket);

const shouldSearchTransactions = (searchIn: EntityType[]): boolean =>
  searchIn.includes(EntityType.Transfer) ||
  searchIn.includes(EntityType.Transaction);

function search(
  searchParams: SearchParams,
  requestParams: {
    service: string;
    entityFilter: EntityType[];
  }
) {
  const { searchTerm, searchIn } = searchParams;
  const page = searchParams.page ?? 1;
  const { service, entityFilter } = requestParams;

  return axios.post<SearchResultGroup[]>(`${service}/GlobalSearch`, {
    searchTerm,
    searchIn: searchIn.filter((entity) => entityFilter.includes(entity)),
    ...{ limit: RESULTS_SIZE, skip: RESULTS_SIZE * (page - 1) },
  });
}

export const getGlobalSearchResults = async (searchParams: SearchParams) => {
  const requests: AxiosPromise[] = [];

  if (shouldSearchFinance(searchParams.searchIn)) {
    requests.push(
      search(searchParams, {
        service: "finance",
        entityFilter: [EntityType.Invoice, EntityType.Remittance],
      })
    );
  }

  if (shouldSearchParticipants(searchParams.searchIn)) {
    requests.push(
      search(searchParams, {
        service: "participant",
        entityFilter: [EntityType.Participant, EntityType.EndMarket],
      })
    );
  }

  if (shouldSearchTransactions(searchParams.searchIn)) {
    requests.push(
      search(searchParams, {
        service: "transactions",
        entityFilter: [EntityType.Transfer, EntityType.Transaction],
      })
    );
  }

  const results: PromiseSettledResult<
    AxiosResponse<SearchResultGroup[]>
  >[] = await Promise.allSettled(requests);

  return results.reduce(
    (
      acc: SearchResultGroup[],
      res: PromiseSettledResult<AxiosResponse<SearchResultGroup[]>>
    ) => {
      if (res.status === "fulfilled") {
        acc.push(...res.value.data);
      }

      return acc;
    },
    []
  );
};

export default getGlobalSearchResults;
