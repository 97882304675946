import React, { PropsWithChildren, useCallback, useRef, useState } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { SocketData } from "../../types/common";
import SocketContext from "../../contexts/socketContext";

interface SocketManagerProps {
  socketUrl: string;
}

const SocketManager = ({
  socketUrl,
  children,
}: PropsWithChildren<SocketManagerProps>) => {
  const [socketData, setSocketData] = useState<SocketData>({
    isConnected: false,
    socketUrl: null,
  });

  const connection = useRef<HubConnection | null>(null);

  const connect = useCallback(
    async (groupName: string, userMail: string) => {
      try {
        connection.current = new HubConnectionBuilder()
          .withUrl(socketUrl)
          .withAutomaticReconnect([500])
          .configureLogging(LogLevel.Information)
          .build();

        await connection.current?.start();

        await connection.current?.invoke("JoinGroup", groupName, userMail);

        connection.current?.onclose(async (error) => {
          if (error) {
            try {
              await connection.current?.start();
            } catch (err) {
              setSocketData({ isConnected: false, socketUrl });
            }
          }
        });

        setSocketData({
          isConnected: true,
          socketUrl,
        });
      } catch {
        setSocketData({ isConnected: false, socketUrl });
      }
    },
    [socketUrl]
  );

  const disconnect = useCallback(async () => {
    if (connection.current) {
      await connection.current?.stop();

      connection.current = null;
    }
  }, []);

  const listen = useCallback(
    async (event: string, callback: (message: string) => void) => {
      connection.current?.on(event, callback);
    },
    []
  );

  const unlisten = useCallback(
    async (event: string, callback: (message: string) => void) => {
      connection.current?.off(event, callback);
    },
    []
  );

  return (
    <SocketContext.Provider
      value={{
        isConnected: socketData.isConnected,
        socketUrl: socketData.socketUrl,
        connect,
        disconnect,
        listen,
        unlisten,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketManager;
