import { TransactionType } from "fe-shared/src/types/domain";
import { SearchResultItem as SearchResultDataItem } from "../../../../types";
import { ENTITIES_TO_LINKS_MAP } from "../../../../consts";

export const getHighlightedTextMarkdown = (
  text: string | null,
  highlight: string
): string => {
  if (!text) return "";

  function escapeStringRegexp(string: string) {
    return string.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
  }

  const parts = text.split(
    new RegExp(`(${escapeStringRegexp(highlight)})`, "gi")
  );

  return parts
    .map((part) =>
      part.toLowerCase() === highlight.toLowerCase() ? `**${part}**` : part
    )
    .join("");
};

export const getEntityUrl = (entity: SearchResultDataItem) => {
  const { entityId, entityType } = entity;

  return `/${ENTITIES_TO_LINKS_MAP[entityType]}/${entityId}`;
};

export const enrichTransactionDisplayName = (
  transactionDisplayName: string | null,
  transactionType: TransactionType | null
): string => {
  if (!transactionDisplayName) return "";
  if (!transactionType) return transactionDisplayName;

  return `${transactionType} ${transactionDisplayName.split(" ")[1]}`;
};

export default {
  enrichTransactionDisplayName,
  getHighlightedTextMarkdown,
  getEntityUrl,
};
