import React from "react";

import { IconProps } from "../../types/common";

const InventoryAdjustmentsIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#fff",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M5.50098 10.3274V19.3274C5.50098 19.8774 5.05098 20.3274 4.50098 20.3274H4.29863C3.74863 20.3274 3.29863 19.8774 3.29863 19.3274V10.3274C2.83863 10.3274 2.62863 9.75736 2.96863 9.45736L11.3286 3.92736C11.7086 3.58736 12.2886 3.58736 12.6686 3.92736L21.0286 9.45736C21.3786 9.75736 21.1586 10.3274 20.6986 10.3274V19.3274C20.6986 19.8774 20.2486 20.3274 19.6986 20.3274H19.501C18.951 20.3274 18.501 19.8774 18.501 19.3274V10.3274H5.50098ZM7.50098 12.5C7.50098 12.2239 7.72483 12 8.00098 12H11.001C11.2771 12 11.501 12.2239 11.501 12.5V13.5C11.501 13.7761 11.2771 14 11.001 14H8.00098C7.72483 14 7.50098 13.7761 7.50098 13.5V12.5ZM7.50098 15.5C7.50098 15.2239 7.72483 15 8.00098 15H11.001C11.2771 15 11.501 15.2239 11.501 15.5V16.5C11.501 16.7761 11.2771 17 11.001 17H8.00098C7.72483 17 7.50098 16.7761 7.50098 16.5V15.5ZM7.50098 18.5C7.50098 18.2239 7.72483 18 8.00098 18H11.001C11.2771 18 11.501 18.2239 11.501 18.5V19.5C11.501 19.7761 11.2771 20 11.001 20H8.00098C7.72483 20 7.50098 19.7761 7.50098 19.5V18.5ZM12.501 18.5C12.501 18.2239 12.7248 18 13.001 18H16.001C16.2771 18 16.501 18.2239 16.501 18.5V19.5C16.501 19.7761 16.2771 20 16.001 20H13.001C12.7248 20 12.501 19.7761 12.501 19.5V18.5ZM12.501 15.5C12.501 15.2239 12.7248 15 13.001 15H16.001C16.2771 15 16.501 15.2239 16.501 15.5V16.5C16.501 16.7761 16.2771 17 16.001 17H13.001C12.7248 17 12.501 16.7761 12.501 16.5V15.5Z"
    />
  </svg>
);

export default InventoryAdjustmentsIcon;
