import React from "react";
import classnames from "classnames";

import styles from "./EmptyState.module.scss";

interface Props {
  title: string;
  iconComponent: React.ElementType;
  subtitle?: string;
  className?: string;
}

const EmptyState = (props: Props) => {
  const { title, subtitle, iconComponent: Icon, className } = props;

  return (
    <div className={classnames(styles.emptyState, className)}>
      {!!Icon && (
        <div className={styles.emptyStateIconCircle}>
          <Icon fillColor={null} />
        </div>
      )}
      <span className={styles.emptyStateTitle}>{title}</span>
      <span className={styles.emptyStateSubtitle}>{subtitle}</span>
    </div>
  );
};

export default EmptyState;
