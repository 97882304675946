import { useEffect, useState, RefObject, useRef } from "react";

interface UseIntersectionObserverProperties {
  ref?: RefObject<Element> | null;
  threshold?: number | number[];
  root?: Element;
  rootMargin?: string;
  handler?: (entries: IntersectionObserverEntry[]) => void;
}

const useIntersectionObserver = ({
  ref,
  handler,
  root,
  rootMargin,
  threshold = 1,
}: UseIntersectionObserverProperties) => {
  const handlerRef = useRef(handler);
  const [inView, setInView] = useState<boolean | null>(null);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      const { isIntersecting } = entries[entries.length - 1];

      if (isIntersecting) handlerRef.current?.(entries);

      setInView(isIntersecting);
    };
    const intersectionObserver = new IntersectionObserver(handleIntersect, {
      root,
      rootMargin,
      threshold,
    });

    if (ref?.current) {
      intersectionObserver.observe(ref.current);
    }

    return () => intersectionObserver.disconnect();
  }, [ref, root, rootMargin, threshold]);

  return inView;
};

export default useIntersectionObserver;
