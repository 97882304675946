import axios from "axios";

import config from "../../../config";

export const userLogout = (): Promise<boolean> => {
  return axios
    .get(`${config.IDENTITY_HOST_URL}api/ExternalAccount/Logout`, {
      withCredentials: true,
    })
    .then(() => true)
    .catch(() => false);
};

export default userLogout;
