import React from "react";
import { useTranslation } from "react-i18next";

import { IconButton } from "fe-shared/src/components";
import { SearchIcon } from "fe-shared/src/components/Icons";

import styles from "../Search.module.scss";

interface Props {
  onClickAction: () => void;
}

const SearchButton = ({ onClickAction }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.headerSearchBlock}>
      <IconButton
        id="global-search-button"
        aria-label={t("search.title")}
        onClick={onClickAction}
      >
        <SearchIcon />
      </IconButton>
    </div>
  );
};

export default SearchButton;
