import { useState, useEffect } from "react";

const useDialog = () => {
  const [isDialogActive, setIsDialogActive] = useState(false);
  const [isDialogRendered, setIsDialogRendered] = useState(false);

  const openDialog = () => setIsDialogRendered(true);
  const closeDialog = () => setIsDialogActive(false);

  useEffect(() => {
    if (isDialogRendered) {
      setIsDialogActive(true);
    }
  }, [isDialogRendered]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!isDialogActive) {
      timeout = setTimeout(() => {
        setIsDialogRendered(false);
      }, 200);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isDialogActive]);

  return {
    isDialogActive,
    isDialogRendered,
    openDialog,
    closeDialog,
  };
};

export default useDialog;
