export enum ApplicationPageCode {
  Transactions = "transactions",
  Applications = "applications",
  Participants = "participants",
}

export interface ApplicationPageLink {
  pageCode: ApplicationPageCode;
  to: string;
  label: string;
}
