import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import LoadMoreButton from "./LoadMoreButton";
import {
  SearchResultItemCommon,
  SearchResultItemTransaction,
} from "./SearchResultsItem";

import {
  SearchResultItem as SearchResultDataItem,
  EntityType,
  SearchResultGroup,
  SearchResultTransactionItem,
} from "../../../types";

import styles from "../Search.module.scss";
import * as api from "../modules/api";
import SearchContext from "../SearchContext";

interface Props {
  title: EntityType;
  resultItems: SearchResultDataItem[];
  totalCount: number;
  isSearching: boolean;
  onLoadMore: (results: SearchResultGroup) => void;
}

const SearchResultsSection = ({
  title,
  resultItems,
  totalCount,
  isSearching,
  onLoadMore,
}: Props) => {
  const { t } = useTranslation();
  const searchTerm = useContext(SearchContext);
  const [resultPage, setResultPage] = useState<number>(1);
  const resultsLength = resultItems.length;

  useEffect(() => {
    setResultPage(1);
  }, [isSearching]);

  const [getMoreGlobalSearchResults, { isLoading }] = useMutation(
    api.getGlobalSearchResults,
    {
      onSuccess: (resp) => {
        const respItem = resp[0];

        onLoadMore(respItem);
      },
    }
  );

  return (
    <div className={styles.searchResultsSection}>
      <p className={styles.searchResultsSectionTitle}>
        {t(`search.entities.${title}`)}
      </p>
      <p className={styles.searchResultsSectionResultsCount}>
        {t("search.resultsFound", { results: totalCount })}
      </p>
      {resultItems.map((item) => {
        if (
          [EntityType.Transaction, EntityType.Transfer].includes(
            item.entityType
          )
        ) {
          return (
            <SearchResultItemTransaction
              key={item.entityId}
              item={item as SearchResultTransactionItem}
            />
          );
        }

        return <SearchResultItemCommon key={item.entityId} item={item} />;
      })}
      {resultsLength < totalCount && (
        <LoadMoreButton
          id={`load-more-search-results-${title}`}
          isLoading={isLoading}
          onMouseDown={() => {
            const nextPage = resultPage + 1;

            setResultPage(nextPage);
            getMoreGlobalSearchResults({
              searchTerm,
              searchIn: [title],
              page: nextPage,
            });
          }}
        />
      )}
    </div>
  );
};

export default SearchResultsSection;
