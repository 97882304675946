import React from "react";
import { useTranslation } from "react-i18next";

import { BusinessRole, TransactionType } from "fe-shared/src/types/domain";
import { SubdirectoryArrow } from "fe-shared/src/components/Icons";

// import { formatTransactionNumber } from "../../helpers/format";

import styles from "./LinkedTransactions.module.scss";

interface Props {
  transactionType: TransactionType;
  linkedTransactionNumber: string | null;
  linkedTransactionType: TransactionType | null;
  targetBusinessRoleCode?: BusinessRole;
}

const childTypes = [TransactionType.Receipt, TransactionType.Pickup];

const LinkedTransactions = ({
  transactionType,
  linkedTransactionType,
  linkedTransactionNumber,
  targetBusinessRoleCode,
}: Props) => {
  const { t } = useTranslation();

  const isChildType = childTypes.includes(transactionType);

  const isDisposition = transactionType === TransactionType.Disposition;

  return (
    <div className={styles.linkedBlock}>
      <SubdirectoryArrow
        className={styles.linkedIcon}
        width={12}
        height={12}
        isUp={isChildType}
      />
      {isDisposition ? (
        <span className={styles.destination}>
          {t(`businessRoles.${targetBusinessRoleCode}`)}
        </span>
      ) : (
        <div className={styles.transactions}>
          <div className={styles.linkedTransaction}>
            {`${t(
              `search.transactionType.${linkedTransactionType}`
            )} ${linkedTransactionNumber}`}
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkedTransactions;
