import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LoginView from "routes/Login";
import PortalView from "routes/Portal";

import UserContext from "contexts/userContext";
import {
  ROUTES,
  USER_DATA_STORAGE_KEY,
  PREV_LOCATION_KEY,
  REFRESH_TOKEN_URL,
  EMPTY_USER_DATA,
  SESSION_ID_STORAGE_KEY,
} from "consts";
import { UserInfo } from "fe-shared/src/types/domain";

import { get, set } from "fe-shared/src/helpers/storage";
import useAuthorizationHeader from "fe-shared/src/hooks/useAuthorizationHeader";
import useAccessForbiddenInterceptor from "fe-shared/src/hooks/useAccessForbiddenInterceptor";
import useAccessRejectedInterceptor from "fe-shared/src/hooks/useAccessRejectedInterceptor";
import useGlobalErrorHandler from "fe-shared/src/hooks/useGlobalErrorHandler";
import useHistoryListener from "fe-shared/src/hooks/useHistoryListener";
import useSentryIdentify from "fe-shared/src/hooks/useSentryIdentify";

import { showNotification } from "fe-shared/src/components";

const App = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [userInfo, setUserInfo] = useState<UserInfo>(
    get(USER_DATA_STORAGE_KEY, EMPTY_USER_DATA)
  );
  const { data, token } = userInfo || {};
  const { historyStack, goBack } = useHistoryListener(
    PREV_LOCATION_KEY,
    ROUTES.root,
    [ROUTES.login, ROUTES.loginCallback]
  );

  const isLoggedIn = useAuthorizationHeader(token);
  const isReady = !!isLoggedIn && !!data;

  useAccessRejectedInterceptor(userInfo, setUserInfo, REFRESH_TOKEN_URL, true);

  useGlobalErrorHandler();

  useEffect(() => {
    set(USER_DATA_STORAGE_KEY, userInfo);
  }, [userInfo]);

  const sessionId = useSentryIdentify(SESSION_ID_STORAGE_KEY, userInfo);

  useEffect(() => {
    if (
      (!token || !data) &&
      ![ROUTES.login, ROUTES.loginCallback].includes(pathname)
    ) {
      history.replace(ROUTES.login);
    } else if (
      token &&
      data &&
      [ROUTES.login, ROUTES.loginCallback].includes(pathname)
    ) {
      goBack();
    }
  }, [history, pathname, token, data, goBack]);

  const forbiddenCallback = () => {
    setUserInfo(EMPTY_USER_DATA);
    showNotification(t("accessForbidden"));
  };

  useAccessForbiddenInterceptor(forbiddenCallback);

  return (
    <UserContext.Provider
      value={{
        sessionId,
        user: userInfo,
        setData: setUserInfo,
      }}
    >
      <Switch>
        {!isReady && (
          <Route exact path={ROUTES.loginCallback}>
            <LoginView historyStack={historyStack} isCallback />
          </Route>
        )}
        {!isReady && (
          <Route exact path={ROUTES.login}>
            <LoginView historyStack={historyStack} />
          </Route>
        )}
        {isReady && (
          <Route path={ROUTES.root}>
            <PortalView forbiddenCallback={forbiddenCallback} />
          </Route>
        )}
      </Switch>
    </UserContext.Provider>
  );
};

export default App;
