import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

import { InfoPlate } from "fe-shared/src/components";
import {
  Participant as ParticipantResultDataItem,
  SearchResultItem as SearchResultDataItem,
  EntityStatus,
} from "../../../../types";

import styles from "../../Search.module.scss";
import SearchContext from "../../SearchContext";
import { getHighlightedTextMarkdown, getEntityUrl } from "./helpers";

interface Props {
  item: SearchResultDataItem;
}

interface ParticipantProps {
  participant: ParticipantResultDataItem;
  searchTerm: string;
}

const Participant = ({ participant, searchTerm }: ParticipantProps) => {
  return (
    <>
      {participant.displayName && (
        <div className={styles.searchResultsItemColumn}>
          <ReactMarkdown>
            {getHighlightedTextMarkdown(participant.displayName, searchTerm)}
          </ReactMarkdown>
          <div className={styles.subtitleContainer}>
            {participant.regNumber && (
              <ReactMarkdown className={styles.subtitle}>
                {getHighlightedTextMarkdown(participant.regNumber, searchTerm)}
              </ReactMarkdown>
            )}
            {participant.businessRoleCode && (
              <div className={styles.subtitle}>
                {participant.businessRoleCode}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const SearchResultItemCommon = ({ item }: Props) => {
  const searchTerm = useContext(SearchContext);

  return (
    <Link
      className={styles.searchResultsItem}
      target="_blank"
      to={getEntityUrl(item)}
    >
      <ReactMarkdown className={styles.itemTitle}>
        {getHighlightedTextMarkdown(item.displayName, searchTerm)}
      </ReactMarkdown>

      {item.content?.participants.length > 0 &&
        item.content.participants.map((participant) => (
          <Participant
            key={participant.regNumber}
            participant={participant}
            searchTerm={searchTerm}
          />
        ))}

      {item.entityStatus === EntityStatus.Inactive && (
        <InfoPlate
          className={[styles.searchResultsItemColumn, styles.lastItem].join(
            " "
          )}
          variant="neutral"
        >
          {item.entityStatus}
        </InfoPlate>
      )}
    </Link>
  );
};

export default SearchResultItemCommon;
