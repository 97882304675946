import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CheckboxIndeterminate,
  CheckboxGroup,
  CheckboxGroupProps,
} from "fe-shared/src/components";

import { EntityType } from "../../../types";

interface Props
  extends Omit<CheckboxGroupProps, "inline" | "value" | "onChange"> {
  value: EntityType[];
  onChange: (event: ChangeEvent<HTMLInputElement>, value: EntityType[]) => void;
}

const SearchEntitiesCheckboxGroup = (props: Props) => {
  const { t } = useTranslation();
  const { id, value, variants, onChange, ...otherProps } = props;

  const [selectedEntities, setSelectedEntities] = useState<EntityType[]>(value);

  return (
    <>
      <CheckboxIndeterminate
        id={`${id}All`}
        title={t("search.entities.all")}
        value={selectedEntities.length === variants.length}
        indeterminate={
          selectedEntities.length < variants.length &&
          selectedEntities.length > 0
        }
        onChange={(e, isAll) => {
          const values = isAll
            ? variants.map((variant) => variant.value as EntityType)
            : [];

          setSelectedEntities(values);
          onChange(e, values);
        }}
      />
      <CheckboxGroup
        inline
        value={selectedEntities}
        variants={variants}
        onChange={(e, values) => {
          setSelectedEntities(values as EntityType[]);
          onChange(e, values as EntityType[]);
        }}
        {...otherProps}
      />
    </>
  );
};

export default SearchEntitiesCheckboxGroup;
