import { TransactionType, BusinessRole } from "fe-shared/src/types/domain";

export interface SearchResultGroup {
  matches: SearchResultItem[];
  searchIn: EntityType;
  total: number;
}

export interface SearchResultItem {
  type: string | null;
  entityType: EntityType;
  entityStatus: EntityStatus;
  displayName: string | null;
  entityId: number;
  content: {
    participants: Participant[];
  };
}

export interface SearchResultTransactionItem extends SearchResultItem {
  linkedTransactionNumber: string | null;
  linkedTransactionType: TransactionType | null;
  transactionType: TransactionType | null;
}

export interface Participant {
  businessRoleCode?: BusinessRole;
  displayName?: string;
  regNumber?: string;
  type?: string;
}

export enum EntityType {
  Invoice = "Invoice",
  Remittance = "Remittance",
  Participant = "Participant",
  EndMarket = "EndMarket",
  Transfer = "Transfer",
  Transaction = "Transaction",
}

export enum EntityStatus {
  Active = "Active",
  Inactive = "Inactive",
}
