import React, { ChangeEvent, Ref, useCallback } from "react";

import { TextInput } from "fe-shared/src/components";
import { SearchIcon } from "fe-shared/src/components/Icons";

import styles from "../Search.module.scss";

interface Props {
  id: string;
  placeholder: string;
  width: number;
  inputRef: Ref<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => void;
}

const SearchInput = ({ onChange, placeholder, id, width, inputRef }: Props) => {
  const onTextInputChange = useCallback(
    (e, term) => {
      onChange(e, term.trim().replace(/^#+/, "").trim());
    },
    [onChange]
  );

  return (
    <div className={styles.searchInput}>
      <SearchIcon
        className={styles.searchInputIcon}
        fillColor="#b5bdc2"
        width={36}
        height={36}
      />
      <TextInput
        id={id}
        ref={inputRef}
        autoFocus
        placeholder={placeholder}
        width={width}
        inputClassName={styles.searchTextInput}
        onChange={onTextInputChange}
      />
    </div>
  );
};

export default SearchInput;
