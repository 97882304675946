import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import classnames from "classnames";

import { Tooltip, FormControlMessage, CheckboxProps } from "../index";

import styles from "./Checkbox.module.scss";

export interface Props extends CheckboxProps {
  indeterminate?: boolean;
}

const CheckboxIndeterminate = (props: Props) => {
  const {
    id,
    title,
    value = false,
    hintText,
    tooltipText,
    icon,
    errorText,
    isError,
    onChange,
    isMultiSelect = false,
    labelClassName,
    wrapperClassName,
    messageClassName,
    indeterminate = false,
    ...otherProps
  } = props;

  const showErrorMessage = !!errorText && isError;

  const describedBy = useMemo(() => {
    if (showErrorMessage) {
      return `${id}-error-msg`;
    }
    return undefined;
  }, [id, showErrorMessage]);

  return (
    <div className={classnames(styles.checkboxWrapper, wrapperClassName)}>
      <div className={styles.checkboxBlock}>
        <input
          id={id}
          type="checkbox"
          aria-invalid={!!isError}
          aria-describedby={describedBy}
          className={styles.checkboxInput}
          onChange={(e) => onChange(e, e.target.checked)}
          ref={(input) => {
            if (input) {
              input.checked = value;
              input.indeterminate = indeterminate;
            }
          }}
          {...otherProps}
        />
        <label
          htmlFor={id}
          className={classnames(labelClassName, styles.checkboxLabel, {
            [styles.checkboxError]: isError,
            [styles.checkboxNoWrap]: isMultiSelect,
          })}
        >
          {title}
        </label>
        {!!tooltipText && (
          <Tooltip>
            <ReactMarkdown source={tooltipText} />
          </Tooltip>
        )}
        {icon}
      </div>
      <FormControlMessage
        id={id}
        errorText={errorText}
        hintText={hintText}
        isError={isError}
        className={classnames(styles.checkboxMessage, messageClassName)}
      />
    </div>
  );
};

export default CheckboxIndeterminate;
