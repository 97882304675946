import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { Node, Edge, Network } from "vis-network";

import { Button, Loader, TextInput } from "fe-shared/src/components";

import * as api from "./modules/api";

import styles from "./MaintenanceView.module.scss";

const MATERIALS_BY_ID: { [key: number]: string } = {
  1: "PLT",
  2: "MT",
  3: "AG/LS",
  4: "IND",
  7: "SOTR",
  8: "MOTR",
  9: "LOTR",
  10: "GOTR",
  11: "SHRD610",
  12: "SHRD26",
  13: "SHRD12",
  14: "TDPC1",
  15: "TDPC2",
  16: "TDPC3",
  17: "STL",
  18: "FBR",
  19: "PWR",
  20: "TDPBS4",
  21: "TDPPT5",
  22: "Rims",
  23: "Pallets",
  24: "IceAndWater",
  25: "DirtAndDebris",
  26: "NonTDTrash",
  27: "TDM",
  28: "TDO",
};

const MaintenanceView = () => {
  const [batchId, setBatchId] = useState("");
  const [materialId, setMaterialId] = useState("");
  const [jsonData, setJsonData] = useState("");
  const visNetwork = useRef<Network>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isManual, setIsManual] = useState(false);

  const buildGraph = ({
    linkedBatches,
    batchAmounts,
  }: api.BatchGraphDetails) => {
    visNetwork.current?.destroy();
    console.log({ linkedBatches, batchAmounts });

    const batchAmountsById = batchAmounts.reduce((result, ba) => {
      result[`${ba.batchId}-${ba.materialId}`] = ba;
      return result;
    }, {} as { [key: string]: api.BatchAmount });

    const nodeBatches: { [key: string]: api.BatchAmount } = {};

    linkedBatches
      .filter(
        (lb) =>
          !materialId ||
          lb.materialId === Number(materialId) ||
          lb.sourceMaterialId === Number(materialId)
      )
      .forEach((lb) => {
        const sourceNodeBatch =
          batchAmountsById[
            `${lb.sourceBatchId}-${lb.sourceMaterialId || lb.materialId}`
          ];
        const targetNodeBatch =
          batchAmountsById[`${lb.nextBatchId}-${lb.materialId}`];

        [sourceNodeBatch, targetNodeBatch].filter(Boolean).forEach((nb) => {
          nodeBatches[`${nb.batchId}-${nb.materialId}`] = nb;
        });
      });

    const nodes: Node[] = Object.values(nodeBatches).map((nb) => ({
      id: `${nb.batchId}-${nb.materialId}`,
      label: `B ${nb.batchId}\nWH ${nb.warehouseId}\n${
        MATERIALS_BY_ID[nb.materialId]
      }`,
    }));

    const edges: Edge[] = linkedBatches.map((lb) => ({
      from: `${lb.sourceBatchId}-${lb.sourceMaterialId || lb.materialId}`,
      to: `${lb.nextBatchId}-${lb.materialId}`,
      label: `${lb.quantity} Q\n${lb.weightPerUnit} WPU`,
      arrows: {
        to: true,
      },
    }));

    console.log({ nodes, edges });
    // @ts-ignore
    window.visData = { nodes, edges, linkedBatches, batchAmounts };

    visNetwork.current = new Network(
      containerRef.current!,
      { nodes, edges },
      {
        nodes: {
          shapeProperties: {
            interpolation: false,
          },
        },
        edges: {
          color: {
            highlight: "#FF0000",
          },
        },
        layout: {
          hierarchical: {
            enabled: true,
            sortMethod: "directed",
          },
          improvedLayout: false,
        },
        physics: false,
      }
    );
  };

  // @ts-ignore
  window.visNetwork = visNetwork.current;

  const [getBatchGraphDetails, { isLoading }] = useMutation(
    () => api.getBatchGraphDetails(batchId),
    {
      onSuccess: buildGraph,
    }
  );

  return (
    <div className={styles.content}>
      {isLoading && <Loader isBlocking />}
      {isManual ? (
        <div className={styles.manualControls}>
          <TextInput
            isMultiline
            value={jsonData}
            id="json-batch-input"
            onChange={(e, v) => setJsonData(v)}
          />
          <TextInput
            id="material-id-input"
            value={materialId}
            onChange={(e, v) => setMaterialId(v)}
            placeholder="Material ID"
          />
          <div className={styles.controls}>
            <Button
              id="get-batch-graph-button"
              onClick={() => {
                try {
                  const data: api.BatchGraphDetails = JSON.parse(jsonData);

                  buildGraph(data);
                  setIsManual(false);
                } catch {
                  // no throw
                }
              }}
            >
              Generate Graph
            </Button>
            <Button
              variant="outlined"
              id="enter-manually-button"
              onClick={() => setIsManual(false)}
            >
              Enter Batch ID
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.controls}>
          <TextInput
            id="batch-id-input"
            value={batchId}
            onChange={(e, v) => setBatchId(v)}
            placeholder="Batch ID"
          />
          <TextInput
            id="material-id-input"
            value={materialId}
            onChange={(e, v) => setMaterialId(v)}
            placeholder="Material ID"
          />
          <Button
            id="get-batch-graph-button"
            onClick={() => getBatchGraphDetails()}
          >
            Get Batch Graph
          </Button>
          <Button
            variant="outlined"
            id="enter-manually-button"
            onClick={() => setIsManual(true)}
          >
            Enter JSON Manually
          </Button>
        </div>
      )}
      <div className={styles.graph} ref={containerRef} />
    </div>
  );
};

export default MaintenanceView;
