type ApplicationConfig = {
  [key: string]: string;
};

interface WindowWithAppConfig extends Window {
  APP_CONFIG?: ApplicationConfig;
}

const { APP_CONFIG = {} } = window as WindowWithAppConfig;

const DEFAULT_CONFIG: ApplicationConfig = {
  IDENTITY_HOST_URL: "https://ids.dev.etrackssrp.com/",
  API_HOST_URL: "https://portal-appgateway.dev.etrackssrp.com/",
  APPLICATIONS_HOST_URL: "https://localhost:3002/",
  TRANSACTIONS_HOST_URL: "https://localhost:3001/",
  REQUESTS_HOST_URL: "https://localhost:3003/",
  ADMIN_HOST_URL: "https://localhost:3004/",
  REMITTANCES_HOST_URL: "https://localhost:3005/",
  HELP_PORTAL_URL: "https://portal-help.dev.etrackssrp.com/",
  EXTERNAL_APP_URL: "https://fe-external-container.dev.etrackssrp.com/",
  SENTRY_DSN: "",
  SENTRY_ENV: "",
};

export default {
  ...DEFAULT_CONFIG,
  ...APP_CONFIG,
};
