import React from "react";

import { IconProps } from "fe-shared/src/types/common";

const SubdirectoryArrow = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#63696F",
  isUp = false,
}: IconProps & { isUp?: boolean }) => (
  <svg
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style, transform: isUp ? "rotate(90deg)" : "" }}
  >
    <path
      fill={fillColor!}
      fillRule="nonzero"
      className={fillClassName}
      d={
        isUp
          ? "M5.00148 15.4187L9.58148 19.9987C9.97148 20.3887 10.6115 20.3887 11.0015 19.9987C11.3915 19.6087 11.3915 18.9687 11.0015 18.5787L8.12148 15.7087H18.2915C18.8415 15.7087 19.2915 15.2587 19.2915 14.7087V4.70874C19.2915 4.15874 18.8415 3.70874 18.2915 3.70874C17.7415 3.70874 17.2915 4.15874 17.2915 4.70874V13.7087H8.12148L11.0015 10.8387C11.3915 10.4487 11.3915 9.80874 11.0015 9.41874C10.6115 9.02874 9.97148 9.02874 9.58148 9.41874L5.00148 13.9987C4.61148 14.3887 4.61148 15.0287 5.00148 15.4187Z"
          : "M18.999 15.4187L14.419 19.9987C14.029 20.3887 13.389 20.3887 12.999 19.9987C12.609 19.6087 12.609 18.9687 12.999 18.5787L15.879 15.7087H5.70898C5.15898 15.7087 4.70898 15.2587 4.70898 14.7087V4.70874C4.70898 4.15874 5.15898 3.70874 5.70898 3.70874C6.25898 3.70874 6.70898 4.15874 6.70898 4.70874V13.7087H15.879L12.999 10.8387C12.609 10.4487 12.609 9.80874 12.999 9.41874C13.389 9.02874 14.029 9.02874 14.419 9.41874L18.999 13.9987C19.389 14.3887 19.389 15.0287 18.999 15.4187Z"
      }
    />
  </svg>
);

export default SubdirectoryArrow;
