import React from "react";

import { IconProps } from "../../types/common";

const AssignmentIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#fff",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M19 4h-4.2A3 3 0 0012 2a3 3 0 00-2.8 2H5a2 2 0 00-2 2v14c0 1.1.9 2 2 2h14a2 2 0 002-2V6a2 2 0 00-2-2zm-7 0c.6 0 1 .5 1 1s-.4 1-1 1a1 1 0 01-1-1c0-.5.4-1 1-1zM8 18h5c.6 0 1-.4 1-1s-.4-1-1-1H8a1 1 0 00-1 1c0 .6.5 1 1 1zm8-4H8a1 1 0 01-1-1c0-.6.5-1 1-1h8c.6 0 1 .4 1 1s-.4 1-1 1zm-8-4h8c.6 0 1-.4 1-1s-.4-1-1-1H8a1 1 0 00-1 1c0 .6.5 1 1 1z"
    />
  </svg>
);

export default AssignmentIcon;
