import React from "react";

import { IconProps } from "../../types/common";

const NotificationBell = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#63696F",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      transform="translate(4,2)"
      fill={fillColor!}
      className={fillClassName}
      d="M14 8.75v5l1.3 1.29a1 1 0 01-.72 1.71H1.41a1 1 0 01-.7-1.71l1.3-1.29v-5c0-3.08 1.62-5.64 4.5-6.32v-.68a1.5 1.5 0 113 0v.68c2.85.68 4.5 3.25 4.5 6.32zm-4 9a2 2 0 11-4 0h4z"
    />
  </svg>
);

export default NotificationBell;
