import React from "react";

import { IconProps } from "../../types/common";

const SearchIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#63696F",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M15.1866 14.4708H15.9766L20.2166 18.7308C20.6266 19.1408 20.6266 19.8108 20.2166 20.2208C19.8066 20.6308 19.1366 20.6308 18.7266 20.2208L14.4766 15.9708V15.1808L14.2066 14.9008C12.8066 16.1008 10.8966 16.7208 8.86658 16.3808C6.08658 15.9108 3.86658 13.5908 3.52658 10.7908C3.00658 6.56084 6.56658 3.00084 10.7966 3.52084C13.5966 3.86084 15.9166 6.08084 16.3866 8.86084C16.7266 10.8908 16.1066 12.8008 14.9066 14.2008L15.1866 14.4708ZM5.47658 9.97084C5.47658 12.4608 7.48658 14.4708 9.97658 14.4708C12.4666 14.4708 14.4766 12.4608 14.4766 9.97084C14.4766 7.48084 12.4666 5.47084 9.97658 5.47084C7.48658 5.47084 5.47658 7.48084 5.47658 9.97084Z"
    />
  </svg>
);

export default SearchIcon;
