import axios from "axios";
import { useEffect, useRef } from "react";

const useAccessForbiddenInterceptor = (callback?: () => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const interceptorId = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const isAccessRejected = error?.response?.status === 403;

        if (isAccessRejected) {
          callbackRef.current?.();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [callbackRef]);
};

export default useAccessForbiddenInterceptor;
