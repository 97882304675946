import React from "react";

import SearchResultsSection from "./SearchResultsSection";

import styles from "../Search.module.scss";

import { SearchResultGroup } from "../../../types";

interface Props {
  results: SearchResultGroup[] | null;
  isSearching: boolean;
  onLoadMore: (results: SearchResultGroup) => void;
}

const SearchResults = ({ results, onLoadMore, isSearching }: Props) => {
  return (
    <div className={styles.searchResultsContainer}>
      {results !== null &&
        results
          .filter((r) => r.total > 0)
          .map((result) => {
            const { matches, total, searchIn } = result;

            return (
              <SearchResultsSection
                key={searchIn}
                resultItems={matches}
                title={searchIn}
                totalCount={total}
                isSearching={isSearching}
                onLoadMore={onLoadMore}
              />
            );
          })}
    </div>
  );
};

export default SearchResults;
