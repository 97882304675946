import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "fe-shared/src/components";
import { StaffNotification } from "types";
import useIntersectionObserver from "fe-shared/src/hooks/useIntersectionObserver";

import NotificationItem from "./NotificationItem";

import styles from "./WebNotification.module.scss";

export interface Props {
  notifications: StaffNotification[];
  unreadCount: string | number;
  removeAll: () => void;
  removeOne: (notification: StaffNotification) => void;
  markAllAsRead: () => void;
  markOneAsRead: (notificationId: number) => void;
  canFetchMore?: boolean;
  fetchMore?: () => void;
}

const NotificationContainer = ({
  notifications,
  unreadCount,
  canFetchMore,
  fetchMore,
  removeAll,
  removeOne,
  markAllAsRead,
  markOneAsRead,
}: Props) => {
  const { t } = useTranslation();
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const isLoadMore = useIntersectionObserver({
    ref: loadMoreRef,
  });

  useEffect(() => {
    if (canFetchMore && isLoadMore) {
      fetchMore!();
    }
  }, [canFetchMore, fetchMore, isLoadMore]);

  const areNotificationsExist = !!notifications.length;

  return (
    <div className={styles.webNotificationContainer}>
      <div className={styles.webNotificationHeader}>
        <div className={styles.notificationCounter}>
          <span>{t("webNotification.notifications")}</span>
          {!!unreadCount && <span>{unreadCount}</span>}
        </div>
        {areNotificationsExist && (
          <Button
            className={styles.markBtn}
            variant="inline"
            id="mark-all-read-btn"
            onClick={unreadCount ? markAllAsRead : removeAll}
          >
            {t(`webNotification.${unreadCount ? "markAsRead" : "removeAll"}`)}
          </Button>
        )}
      </div>
      <div className={styles.webNotificationItems}>
        {areNotificationsExist ? (
          notifications.map((notification) => {
            return (
              <NotificationItem
                key={notification.key}
                notification={notification}
                removeOne={removeOne}
                markOneAsRead={markOneAsRead}
              />
            );
          })
        ) : (
          <div className={styles.webNotificationEmpty}>
            {t("webNotification.empty")}
          </div>
        )}
        <div ref={loadMoreRef} />
      </div>
    </div>
  );
};

export default NotificationContainer;
