import React from "react";
import classnames from "classnames";

import styles from "./InfoPlate.module.scss";

export type InfoPlateVariant =
  | "neutral"
  | "info"
  | "success"
  | "warning"
  | "error"
  | "dark"
  | "success-dark"
  | "active"
  | "black"
  | "purple"
  | "orange"
  | "complete";

interface Props {
  className?: string;
  variant?: InfoPlateVariant;
  children: React.ReactNode;
}

const InfoPlate = (props: Props) => {
  const { children, className, variant = "neutral" } = props;

  return (
    <span className={classnames(styles.infoPlate, styles[variant], className)}>
      {children}
    </span>
  );
};

export default InfoPlate;
