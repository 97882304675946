import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import formatRelative from "date-fns/formatRelative";
import parseJSON from "date-fns/parseJSON";
import enUS from "date-fns/locale/en-US";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

import { CloseIcon } from "fe-shared/src/components/Icons";
import { IconButton } from "fe-shared/src/components";
import { handleMouseDown } from "fe-shared/src/helpers/buttonHandlers";
import { StaffNotification, StaffNotificationMessageLinks } from "types";
import { ENTITIES_TO_LINKS_MAP } from "consts";

import styles from "./WebNotification.module.scss";

interface Props {
  notification: StaffNotification;
  removeOne: (notification: StaffNotification) => void;
  markOneAsRead: (notificationId: number) => void;
}

const formatRelativeLocale: { [key: string]: string } = {
  lastWeek: "dd MMMM yyyy",
  yesterday: "'Yesterday' p",
  today: "'Today' p",
  tomorrow: "'Tomorrow' p",
  nextWeek: "dd MMMM yyyy",
  other: "dd MMMM yyyy",
};

const NotificationItem = (props: Props) => {
  const { t } = useTranslation();
  const {
    removeOne,
    markOneAsRead,
    notification,
    notification: { notificationId, message, createdAt, isRead, links },
  } = props;

  const markdownLinksMapper = (
    markdownString: string,
    markdownLinks: StaffNotificationMessageLinks[],
    label: string
  ) =>
    markdownLinks.reduce((acc, markdownLink) => {
      const { type, entityId, entityType, displayName } = markdownLink;
      const isRequest = entityType === "Request";
      const linkTitle = isRequest
        ? t(`webNotification.requests.${type}`)
        : displayName;

      return acc.replace(
        label,
        () =>
          `[${linkTitle}](/${ENTITIES_TO_LINKS_MAP[entityType]}/${entityId})`
      );
    }, markdownString);

  const onNotificationClick = () => {
    if (!isRead) {
      markOneAsRead(notificationId);
    }
  };

  return (
    <div className={styles.notificationItem}>
      <div className={styles.notificationItemTop}>
        <IconButton
          aria-label={t("webNotification.remove")}
          id={`remove-notification-${notificationId}`}
          onClick={() => removeOne(notification)}
          onMouseDown={handleMouseDown}
          className={classnames(
            styles.notificationItemRemove,
            !isRead && styles.notificationItemRemoveUnread
          )}
        >
          <CloseIcon width={11} height={11} />
        </IconButton>
        {!isRead && <div className={styles.notificationItemCircle} />}
      </div>
      <ReactMarkdown
        source={markdownLinksMapper(message, links, "[link]")}
        renderers={{
          link: (linkProps) => (
            <Link to={linkProps.href} onClick={onNotificationClick}>
              {linkProps.children}
            </Link>
          ),
        }}
      />
      <span className={styles.notificationItemDate}>
        {formatRelative(parseJSON(createdAt), new Date(), {
          locale: {
            ...enUS,
            formatRelative: (token: string) => formatRelativeLocale[token],
          },
        })}
      </span>
    </div>
  );
};

export default NotificationItem;
