import axios from "axios";

import { StaffNotification } from "types";
import { NOTIFICATIONS_NUMBER_LIMIT } from "consts";

export interface WebNotificationsResponse {
  total: number;
  unreadCount: number;
  notifications: StaffNotification[];
}

export const getNotifications = (
  page: number | null = 1
): Promise<WebNotificationsResponse> =>
  axios
    .post<WebNotificationsResponse>(`notification/getWebNotificationsForUser`, {
      ...(page !== null
        ? {
            limit: NOTIFICATIONS_NUMBER_LIMIT,
            skip: NOTIFICATIONS_NUMBER_LIMIT * (page - 1),
          }
        : {}),
    })
    .then((res) => res.data);

export const markOneAsRead = (notificationId: number): Promise<boolean> =>
  axios
    .post(`notification/readNotification`, {
      notificationId,
    })
    .then(() => true);

export const removeOne = ({
  notificationId,
}: StaffNotification): Promise<boolean> =>
  axios
    .post(`notification/deleteNotification`, {
      notificationId,
    })
    .then(() => true);

export const markAllAsRead = (): Promise<boolean> =>
  axios.post(`notification/markAllNotificationsAsRead`, null).then(() => true);

export const removeAll = (): Promise<boolean> =>
  axios.post(`notification/deleteAllUsersNotifications`, null).then(() => true);
