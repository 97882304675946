import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { Dialog, EmptyState, Loader } from "fe-shared/src/components";
import { ErrorIcon } from "fe-shared/src/components/Icons";

import SearchForm from "./components/SearchForm";
import SearchResults from "./components/SearchResults";
import SearchContext from "./SearchContext";
import * as api from "./modules/api";
import styles from "./Search.module.scss";
import { SearchResultGroup } from "../../types";

interface Props {
  isActive: boolean;
  onClose: () => void;
}

const SearchDialog = ({ isActive, onClose }: Props) => {
  const searchInputRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [searchResults, setSearchResults] = useState<
    SearchResultGroup[] | null
  >(null);
  const [getGlobalSearchResults, { isLoading, isSuccess }] = useMutation(
    api.getGlobalSearchResults,
    {
      onSuccess: (resp) => {
        setSearchResults(resp);
      },
    }
  );

  const isEmptyState =
    searchResults !== null &&
    (isSuccess || isLoading) &&
    !searchResults.some((s) => s.total > 0);

  useEffect(() => {
    if (isActive) {
      searchInputRef.current?.focus();
    }
  }, [isActive]);

  return (
    <Dialog
      isActive={isActive}
      onClose={onClose}
      title={t("search.title")}
      width={880}
    >
      <SearchContext.Provider value={search}>
        {isLoading && <Loader isBlocking />}
        <SearchForm
          searchInputRef={searchInputRef}
          onSubmit={(searchTerm, searchIn) => {
            getGlobalSearchResults({ searchTerm, searchIn });
            setSearch(searchTerm);
          }}
        />
        {isEmptyState ? (
          <EmptyState
            className={styles.emptyState}
            iconComponent={ErrorIcon}
            title={t("search.noResults")}
          />
        ) : (
          <SearchResults
            results={searchResults}
            isSearching={isLoading}
            onLoadMore={(moreResults) => {
              if (searchResults !== null) {
                setSearchResults(
                  searchResults.map((res) => {
                    if (res.searchIn === moreResults.searchIn) {
                      res.matches.push(...moreResults.matches);
                    }
                    return res;
                  })
                );
              }
            }}
          />
        )}
      </SearchContext.Provider>
    </Dialog>
  );
};

export default SearchDialog;
