import React from "react";

import WebNotification from "components/WebNotification";

import styles from "./QuickLaunchButtons.module.scss";

const QuickLaunchButtons = () => {
  return (
    <div className={styles.etQuickLaunch}>
      <WebNotification />
    </div>
  );
};

export default QuickLaunchButtons;
