import React, { Ref, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "fe-shared/src/components";

import SearchInput from "./SearchInput";
import SearchEntitiesCheckboxGroup from "./SearchEntitiesCheckboxGroup";

import { searchEntities } from "../config";

import styles from "../Search.module.scss";
import { EntityType } from "../../../types";

interface Props {
  onSubmit: (searchTerm: string, selectedEntities: EntityType[]) => void;
  searchInputRef: Ref<HTMLInputElement | HTMLTextAreaElement>;
}

const SearchForm = ({ onSubmit, searchInputRef }: Props) => {
  const { t } = useTranslation();
  const [selectedEntities, setSelectedEntities] = useState<EntityType[]>(
    searchEntities
  );
  const [searchTerm, setSearchTerm] = useState<string>("");

  const variants = useMemo(
    () =>
      searchEntities.map((entity: EntityType) => ({
        id: entity,
        value: entity,
        title: t(`search.entities.${entity}`),
      })),
    [t]
  );
  const canSubmit = useMemo(
    () => selectedEntities.length > 0 && searchTerm.length > 2,
    [selectedEntities, searchTerm]
  );

  return (
    <div className={styles.formContainer}>
      <form
        className={styles.inputContainer}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(searchTerm, selectedEntities);
        }}
      >
        <SearchInput
          id="global-search-input"
          placeholder={t("search.placeholder")}
          width={710}
          inputRef={searchInputRef}
          onChange={(e, term) => setSearchTerm(term)}
        />
        <Button
          type="submit"
          id="global-search-submit-button"
          disabled={!canSubmit}
        >
          {t("search.formSubmitButtonText")}
        </Button>
      </form>
      <div className={styles.checkboxContainer}>
        <SearchEntitiesCheckboxGroup
          name="searchEntities"
          id="searchEntities"
          onChange={(e, v) => {
            setSelectedEntities(v);
          }}
          value={selectedEntities}
          variants={variants}
        />
      </div>
    </div>
  );
};

export default SearchForm;
