import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "fe-shared/src/components";

import styles from "../Search.module.scss";

const LoadMoreButton = ({
  id,
  isLoading,
  onMouseDown,
}: {
  id: string;
  isLoading: boolean;
  onMouseDown: () => void;
}) => {
  const { t } = useTranslation();

  return isLoading ? (
    <Button id={`${id}-loading`} disabled variant="outlined" type="button">
      {t("search.loading")}
    </Button>
  ) : (
    <Button
      id={id}
      className={styles.loadMoreButton}
      type="button"
      variant="outlined"
      onMouseDown={onMouseDown}
    >
      {t("search.loadMoreSearchResults")}
    </Button>
  );
};

export default LoadMoreButton;
