import React, { useEffect } from "react";

import useDialog from "fe-shared/src/hooks/useDialog";

import SearchButton from "./components/SearchButton";
import SearchDialog from "./SearchDialog";

interface KeyboardEvent {
  key: string;
}

const Search = () => {
  const {
    isDialogRendered,
    isDialogActive,
    openDialog,
    closeDialog,
  } = useDialog();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" && isDialogActive) closeDialog();
    };

    if (isDialogActive) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (isDialogActive) {
        window.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [closeDialog, isDialogActive]);

  return (
    <>
      <SearchButton onClickAction={() => openDialog()} />

      {isDialogRendered && (
        <SearchDialog isActive={isDialogActive} onClose={closeDialog} />
      )}
    </>
  );
};

export default Search;
