import React, { useState, useContext, useMemo } from "react";
import { queryCache, useInfiniteQuery, useMutation } from "react-query";
import Tippy, { TippyProps } from "@tippyjs/react";
import { AxiosError } from "axios";
import { useDebouncedCallback } from "use-debounce";

import { StaffNotification } from "types";
import { NotificationBell } from "fe-shared/src/components/Icons";
import { handleMouseDown } from "fe-shared/src/helpers/buttonHandlers";
import { IconButton } from "fe-shared/src/components";
import useSocket from "fe-shared/src/hooks/useSocket";
import { ApiError } from "fe-shared/src/types/common";
import { NOTIFICATIONS_NUMBER_LIMIT } from "consts";

import NotificationContainer from "./NotificationContainer";
import UserContext from "../../contexts/userContext";
import * as api from "./modules/api";

import styles from "./WebNotification.module.scss";

const popperOptions: TippyProps["popperOptions"] = {
  strategy: "fixed",
  modifiers: [
    {
      name: "preventOverflow",
    },
  ],
};

const WebNotification = () => {
  const [isOpen, setOpen] = useState(false);
  const { callback: debouncedInvalidate } = useDebouncedCallback(() => {
    queryCache.invalidateQueries("WebNotifications");
  }, 2000);

  const {
    user: { token, data },
  } = useContext(UserContext);

  const notifications = useInfiniteQuery(
    "WebNotifications",
    (_, page: number = 1) => api.getNotifications(page),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getFetchMore: (lastGroup, allGroups) => {
        return allGroups.length * NOTIFICATIONS_NUMBER_LIMIT < lastGroup.total
          ? allGroups.length + 1
          : undefined;
      },
    }
  );
  const unreadNotificationsNumber = notifications.data?.[0].unreadCount || 0;
  const unreadCount =
    unreadNotificationsNumber > 9 ? "9+" : unreadNotificationsNumber;

  const [markOneAsRead] = useMutation<boolean, AxiosError<ApiError>, number>(
    api.markOneAsRead,
    {
      onMutate: (notificationId) => {
        queryCache.setQueryData(
          "WebNotifications",
          (prevCache?: api.WebNotificationsResponse[]) =>
            prevCache
              ? prevCache.map((page) => ({
                  total: page.total,
                  unreadCount: page.unreadCount - 1,
                  notifications: page.notifications.map((item) =>
                    item.notificationId === notificationId
                      ? { ...item, isRead: true }
                      : item
                  ),
                }))
              : []
        );
      },
      onError: debouncedInvalidate,
    }
  );

  const [markAllAsRead] = useMutation<boolean, AxiosError<ApiError>, string>(
    api.markAllAsRead,
    {
      onMutate: () => {
        queryCache.setQueryData(
          "WebNotifications",
          (prevCache?: api.WebNotificationsResponse[]) =>
            prevCache
              ? prevCache.map((page) => ({
                  total: page.total,
                  unreadCount: 0,
                  notifications: page.notifications.map((item) => ({
                    ...item,
                    isRead: true,
                  })),
                }))
              : []
        );
      },
      onError: debouncedInvalidate,
    }
  );

  const [removeOne] = useMutation<
    boolean,
    AxiosError<ApiError>,
    StaffNotification
  >(api.removeOne, {
    onMutate: (notification) => {
      queryCache.setQueryData(
        "WebNotifications",
        (prevCache?: api.WebNotificationsResponse[]) =>
          prevCache
            ? prevCache.map((page) => ({
                total: page.total - 1,
                unreadCount: page.unreadCount - (notification.isRead ? 0 : 1),
                notifications: page.notifications.filter(
                  (item) => item.notificationId !== notification.notificationId
                ),
              }))
            : []
      );
    },
    onSettled: debouncedInvalidate,
  });

  const [removeAll] = useMutation<boolean, AxiosError<ApiError>, void>(
    api.removeAll,
    {
      onMutate: () => {
        queryCache.setQueryData("WebNotifications", [
          {
            total: 0,
            notifications: [],
          },
        ]);
      },
      onError: debouncedInvalidate,
    }
  );

  useSocket(
    token!,
    "webNotificationUpdate",
    data!.internalRoles![0],
    data!.email,
    debouncedInvalidate
  );

  const fetchedNotifications = useMemo(
    () =>
      notifications.data?.reduce(
        (
          acc: StaffNotification[],
          element: api.WebNotificationsResponse,
          idx
        ) =>
          acc.concat(
            element.notifications.map((n) => ({
              ...n,
              key: `${idx}-${n.notificationId}`,
            }))
          ),
        []
      ) || [],
    [notifications.data]
  );

  const content = (
    <NotificationContainer
      canFetchMore={notifications.canFetchMore}
      fetchMore={notifications.fetchMore}
      notifications={fetchedNotifications}
      unreadCount={unreadCount}
      removeAll={removeAll}
      removeOne={removeOne}
      markAllAsRead={markAllAsRead}
      markOneAsRead={markOneAsRead}
    />
  );

  const onTooltipClose = () => {
    queryCache.setQueryData(
      "WebNotifications",
      (prevCache?: api.WebNotificationsResponse[]) => {
        return prevCache ? [prevCache[0]] : [];
      }
    );

    setOpen(false);
  };

  return (
    <Tippy
      placement="bottom"
      content={content}
      popperOptions={popperOptions}
      arrow={false}
      offset={[0, 2]}
      className={styles.tooltip}
      interactive
      visible={isOpen}
      onClickOutside={onTooltipClose}
    >
      <div className={styles.webNotification}>
        <IconButton
          aria-label="notification"
          id="web-notification"
          onClick={() => setOpen(!isOpen)}
          className={styles.notificationBell}
          onMouseDown={handleMouseDown}
        >
          {!!unreadNotificationsNumber && (
            <span className={styles.unreadMarker} />
          )}
          <NotificationBell fillColor={null} />
        </IconButton>
      </div>
    </Tippy>
  );
};

export default WebNotification;
