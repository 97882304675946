import React, { ChangeEvent, ReactNode } from "react";
import classnames from "classnames";

import { Checkbox, FormControlMessage } from "..";
import styles from "./CheckboxGroup.module.scss";

export interface CheckboxVariant {
  id: string;
  title: string;
  value: unknown;
  hintText?: string | ReactNode;
  tooltipText?: string;
  icon?: ReactNode;
  disabled?: boolean;
}

export interface Props {
  id?: string;
  name: string;
  inline?: boolean;
  value: unknown[];
  errorText?: string;
  isError?: boolean;
  hintText?: string | ReactNode;
  className?: string;
  wrapperClassName?: string;
  checkboxClassName?: string;
  messageClassName?: string;
  variants: CheckboxVariant[];
  onChange: (event: ChangeEvent<HTMLInputElement>, value: unknown[]) => void;
  disabled?: boolean;
}

const defaultProps = {
  variants: [],
};

const CheckboxGroup = (props: Props) => {
  const {
    id,
    inline = false,
    name,
    variants = defaultProps.variants,
    errorText,
    isError,
    hintText,
    className,
    wrapperClassName,
    checkboxClassName,
    messageClassName,
    onChange,
    disabled,
  } = props;

  const value = props.value || [];

  const onValueChange = (
    e: ChangeEvent<HTMLInputElement>,
    isChecked: boolean,
    variantValue: unknown
  ) => {
    onChange(
      e,
      isChecked
        ? [...value, variantValue]
        : value.filter((v) => v !== variantValue)
    );
  };

  return (
    <div className={wrapperClassName}>
      <div
        id={id}
        className={classnames(
          {
            [styles.checkboxGroupWrapper]: true,
            [styles.checkboxGroupWrapperBlock]: !inline,
          },
          className
        )}
      >
        {variants.map((variant) => (
          <Checkbox
            {...variant}
            isError={isError}
            name={name}
            key={variant.id}
            id={`${name}-${variant.id}`}
            wrapperClassName={checkboxClassName}
            value={value.includes(variant.value)}
            onChange={(e, isChecked) => {
              onValueChange(e, isChecked, variant.value);
            }}
            disabled={disabled ?? variant.disabled}
          />
        ))}
      </div>
      <FormControlMessage
        id={name}
        errorText={errorText}
        isError={isError}
        hintText={hintText}
        className={classnames(styles.checkboxGroupMessage, messageClassName)}
      />
    </div>
  );
};

export default CheckboxGroup;
