import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EMPTY_USER_DATA } from "consts";
import UserContext from "contexts/userContext";

import { IconButton } from "fe-shared/src/components";
import { LogoutIcon } from "fe-shared/src/components/Icons";

import * as api from "./modules/api";

import styles from "./UserProfile.module.scss";

const UserProfile = () => {
  const { t } = useTranslation();
  const {
    setData,
    user: { data },
  } = useContext(UserContext);

  const onLogoutClick = () => {
    api.userLogout().then(() => {
      setData(EMPTY_USER_DATA);
    });
  };

  const username = useMemo(() => `${data?.firstName} ${data?.lastName}`, [
    data,
  ]);

  return (
    <div className={styles.userProfile}>
      <span className={styles.userProfileName}>
        {t("user.profile.welcome", {
          username,
        })}
      </span>
      <IconButton
        aria-label="Logout"
        id="user-profile-logout-button"
        onClick={onLogoutClick}
        className={styles.userProfileLogoutButton}
      >
        <LogoutIcon fillColor={null} />
      </IconButton>
    </div>
  );
};

export default UserProfile;
