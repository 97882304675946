import { EntityType } from "../../types";

// eslint-disable-next-line import/prefer-default-export
export const searchEntities: EntityType[] = [
  EntityType.Transaction,
  EntityType.Transfer,
  EntityType.Participant,
  EntityType.Invoice,
  EntityType.Remittance,
  EntityType.EndMarket,
];

export const RESULTS_SIZE = 5;
